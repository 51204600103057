'use strict';
import { PDFDocument, StandardFonts } from 'pdf-lib';
import $ from 'jquery'; require("jquery-html5-validity")($);
import PortrayCanvas from 'portraycanvas';
document.cookie = 'cookie1=value1; SameSite=Lax';
document.cookie = 'cookie2=value2; SameSite=None; Secure';

// FORM FIELDS
let associatedName = document.querySelector('#associatedName');
let associatedSurname = document.querySelector('#associatedSurname');
let birthPlace = document.querySelector('#birthPlace');
let birthDate = document.querySelector('#birthDate');
let fiscalCode = document.querySelector('#fiscalCode');
let email = document.querySelector('#emailType');
let telephone = document.querySelector('#telephone');
let fronteBtn = document.querySelector('#fronteBtn');
let retroBtn = document.querySelector('#retroBtn');
let photoFronte = null;
let photoRetro = null;
let signatureDemand = null;

$('#loading').hide();
$('#checkedIconF').hide();
$('#checkedIconR').hide();

$(document).ready(() => {
// EVENT LISTENERS

birthDate.addEventListener('change', (e) => {
  const bDate = new Date(birthDate.value);
  // 18 years
  const legalDate = 568080000000; 
  // control for being over 18
  if( new Date() - bDate <= legalDate){
    console.log(e);
    setTimeout(() => {
      birthDate.setCustomValidity('Devi essere maggiorenne per registrarti!');
      birthDate.reportValidity();
    }, 500);
  } else {
    birthDate.setCustomValidity('');
  }
});

$('#cameraInputFronte').on('change', (e) => {
  let file = e.originalEvent.target.files[0];
  let reader = new FileReader();

  reader.readAsBinaryString(file);
  reader.onload = (es) => {
    const photoData64 = `data:${file.type};base64, ${btoa(reader.result)}`;
    resizeBase64Img(photoData64, 0.50).then(result => {
      photoFronte = {
        data: result,
        fileType: '.jpeg'
        // file.name.slice(-4)
      }
    });
  };
  // green when file is uploaded
  if (file) {
    $('#checkedIconF').show();
    fronteBtn.style.color = 'rgba(35, 245, 147, 0.8)';
    fronteBtn.style.borderColor = 'rgba(35, 245, 147, 0.8)';
  }
});

$('#cameraInputRetro').on('change', (e) => {
  let file = e.originalEvent.target.files[0];
  let reader = new FileReader();

  reader.readAsBinaryString(file);
  reader.onload = (es) => {
    const photoData64 = `data:${file.type};base64, ${btoa(reader.result)}`;
    resizeBase64Img(photoData64, 0.50).then(result => {
      photoRetro = {
        data: result,
        fileType: '.jpeg' 
      }
    });
  };
  // green when file is uploaded
  if (file) {
    $('#checkedIconR').show();
    retroBtn.style.color = 'rgba(35, 245, 147, 0.8)';
    retroBtn.style.borderColor = 'rgba(35, 245, 147, 0.8)';
  }
});

// Form check validation before send data
document.getElementById('btnSubmit').addEventListener('click', () => {
  let formInputs = new Array(...$('#myForm')[0]);
  let validInputs = formInputs.filter((item) => item.validity.valid);
  let photosIsUploaded = validInputs.some(item => item.id === 'cameraInputFronte') && validInputs.some(item => item.id === 'cameraInputRetro');

  if ($('#myForm').isValid()) {
    $('#myForm').submit(function (e) {
      // Prevent form submission which refreshes page
      e.preventDefault();
      openSignatureModal();
    });
  } else {
    // check documents upload
    if ((validInputs.length === 10 || validInputs.length === 11) && !photosIsUploaded) {
      alert('Carica le foto del documento!');
    } else {
      alert('Compila tutto il modulo prima di mandare i dati!');
    }
  }
});

// METHODS
async function loadPdfDocument() {
  // Get the PDFDocument model
  const url = ('https://utopiasociety.it/assets/modulo_tessere.pdf');
  // api call for pdf
  const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());
  const pdfDocLoaded = await PDFDocument.load(existingPdfBytes);

  return pdfDocLoaded;
}

function openSignatureModal() {
  // SIGNATURE MODAL
  let modal = document.getElementById("myModal");
  // Get the <span> element that closes the modal
  const span = document.getElementById("closeBtn");
  // signature pad
  const canvas = new PortrayCanvas(document.getElementById('signature-pad'), {
    lineWidth: 2,
    color: '#00000',
    // period in which it collects points
    period: 5
  });

  // open the modal
  modal.style.display = "block";

  // close the modal
  span.onclick = function() {
    modal.style.display = "none";
    canvas.clear();
  }
  window.onclick = function(event) {
    if (event.target == modal) {
      modal.style.display = "none";
      canvas.clear();
    }
  }

  // when finished to draw
  canvas.onLineFinish = () => {
    signatureDemand = canvas.canvas.toDataURL();
  }
  // clear button
  $('#resetSign').on('click', () => {
    canvas.clear();
  });

  $('#confirm-sign').on('click', () => {
    if (signatureDemand && canvas.getLines().length) {
      fillPdfWithForm();
      modal.style.display = "none";
      canvas.clear();
    } else {
      alert('Firmare la domanda di ammissione!');
    };
  });

}

async function fillPdfWithForm() {
    const associatedFilefullName = (associatedName.value + '-' + associatedSurname.value).toUpperCase();
    const associatedfullName = associatedName.value + ' ' + associatedSurname.value;
    // email attachments
    let emailAttachments = [];
    // PDF managing
    const pdfDoc = await loadPdfDocument();
    // PDF signature
    const signatureImg = await pdfDoc.embedPng(signatureDemand);
    const signatureDims = signatureImg.scale(0.3);
    // Get the pages of the document
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];
    const secondPage = pages[1];
    // Get the width and height of the page
    const { width, height } = firstPage.getSize();
    // Embed the font
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const courierFont = await pdfDoc.embedFont(StandardFonts.Courier);
    const fontSize = 9;

    // Draw a string of text toward the top of the page
    // new associate
    firstPage.drawText('X', {
      x: 114.5,
      y: height - 126,
      size: 14,
      font: helveticaFont
    });
    // socio name
    firstPage.drawText(associatedName.value+' '+associatedSurname.value, {
      x: 150,
      y: height - 176,
      size: fontSize,
      font: courierFont
    });
    // birth place
    firstPage.drawText(birthPlace.value, {
      x: 130,
      y: height - 195,
      size: fontSize,
      font: courierFont
    });
    // birth date
    firstPage.drawText(convertDate(birthDate.value), {
      x: 456.5,
      y: height - 193.5,
      size: fontSize,
      font: courierFont
    });
    // codice fiscale
    firstPage.drawText(fiscalCode.value, {
      x: 110,
      y: height - 213,
      size: fontSize,
      font: courierFont
    });
    // email
    firstPage.drawText(email.value, {
      x: 110,
      y: height - 232,
      size: fontSize,
      font: courierFont
    });
    // telephone
    firstPage.drawText(telephone.value, {
      x: 435,
      y: height - 232,
      size: fontSize,
      font: courierFont
    });
    // currentDate
    firstPage.drawText(convertDate(new Date(), true), {
      x: 92,
      y: height - 512,
      size: fontSize,
      font: courierFont
    });
    // signature first page
    firstPage.drawImage(signatureImg, {
      x: 390,
      y: height - 530,
      width: signatureDims.width,
      height: signatureDims.height
    });
    // currentDate second page
    secondPage.drawText(convertDate(new Date(), true), {
      x: 90,
      y: height - 742,
      size: fontSize,
      font: courierFont
    });
    // signature first page
    secondPage.drawImage(signatureImg, {
      x: 390,
      y: height - 760,
      width: signatureDims.width,
      height: signatureDims.height
    });

    // email pdf file
    // Serialize the PDFDocument to bytes (a Base64)
    const pdfBytes = await pdfDoc.saveAsBase64({ dataUri: true });
    const pdfFileName = 'TESSERA_SOCIO_' + associatedFilefullName + '.pdf';

    emailAttachments.push(
      {
        name: pdfFileName,
        data: pdfBytes
      },
      {
        name: 'doc_fronte' + photoFronte.fileType,
        data: photoFronte.data
      },
      {
        name: 'doc_retro' + photoRetro.fileType,
        data: photoRetro.data
      }
    )

    if (emailAttachments.length) {
      showLoader();
      sendEmail(associatedfullName, emailAttachments);
    }
}

function showLoader() {
  $('#loading').show();
}

function resizeBase64Img(base64, percentResize) {
  return new Promise((resolve, reject) => {
      let myCanvas = document.createElement("canvas");
      let ctx = myCanvas.getContext("2d");
      let tempCanvas = document.createElement("canvas");
      let tctx = tempCanvas.getContext("2d");

      let img = new Image();
      img.crossOrigin = 'anonymous';
      img.src = base64;
      img.onload = start;

      function start() {
        myCanvas.width = img.width;
        myCanvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        resizeTo(myCanvas, percentResize);
      }
      
      function resizeTo(canvas, percent) {
        let cw = canvas.width;
        let ch = canvas.height;

        tempCanvas.width = cw;
        tempCanvas.height = ch;

        tctx.drawImage(canvas,0,0);

        canvas.width *= percent;
        canvas.height *= percent;

        let ctx=canvas.getContext('2d');
        ctx.drawImage(tempCanvas,0,0,cw,ch,0,0,cw*percent,ch*percent);

        resolve(canvas.toDataURL('image/jpeg', '0.1'));
      }
      
    }
  );
}

function convertDate(inputFormat, current = false) {
  function pad(s) { return (s < 10) ? '0' + s : s; }
  let d = new Date(inputFormat);
  return [pad(current ? d.getDate() - 1 : d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join(' ');
}

function sendEmail(fullName, attachments) {
  const currentYear = new Date();
  const codCurrentYear = 'UT' + currentYear.getFullYear().toString().slice(-2) + '-' 
                       + (currentYear.getFullYear() + 1).toString().slice(-2);

  Email.send({
      Host: "smtp.elasticemail.com",
      Username: "tessereutopia@gmail.com",
      // Username: "zyro.absolute@gmail.com",
      Password: "FC788E75ADF7B77E2FC25BAD6FB32C3197C1",
      // Password: "EE5B3A79D353B2AD997631BE3ABCEFB93B09",
      To: 'tessere.utopia@gmail.com',
      // To: "zyro.absolute@gmail.com",
      From: "tessere.utopia@gmail.com",
      Subject: 'TESSERA SOCIO • ' + fullName.toUpperCase()  + ' • ' + codCurrentYear,
      Body: "<html><h2>Tessera socio " + fullName + "</h2>"+
             "<strong>UTOPIA SOCIETY</strong><br></br><em>Padova, " + currentYear.getFullYear() + "</em>"+
             "</html>",
      Attachments: attachments
  }).then(
    message => {
      if (message === 'OK') {
        location.reload();
        alert('Dati inviati correttamente!');
      }
    }, err => { alert(err) }
  );
  }

}); // end document
